import React, { useCallback, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { H5 } from '@styles/Typography';

import { Wrapper, Section } from '@components/Layout/styles';

import { RightArrowCircleIcon, SearchIcon } from '@components/Icons';

import SEO from '@components/seo';
import Breadcrumb from '@components/Breadcrumb';
import CTA from '@components/CTAS/Appointment';
import InnerBanner from '@components/InnerBanner';
import Sidebar from '@components/Sidebars/Main';
import TextField from '@components/TextField';

import * as S from './_styles';

const PerguntasFrequentesPage = () => {
	const [questions, setQuetions] = useState([]);
	const [term, setTerm] = useState('');

	const { meta, pageData, questionsList } = useStaticQuery(graphql`
		{
			meta: allCosmicjsMeta(filter: { slug: { eq: "meta-faq" } }) {
				nodes {
					metadata {
						title
						description
						cover {
							imgix_url
						}
					}
				}
			}

			pageData: allCosmicjsPages(filter: { slug: { eq: "faq" } }) {
				nodes {
					metadata {
						description
						title
						banner {
							url
							imgix_url
						}
					}
				}
			}

			questionsList: allCosmicjsFaq(sort: { order: ASC, fields: title }) {
				nodes {
					slug
					title
				}
			}
		}
	`);

	const {
		metadata: { title: pageTitle, description: pageDescription, banner },
	} = pageData.nodes[0];

	const {
		metadata: { title: metaTitle, description: metaDescription, cover },
	} = meta.nodes[0];

	const normalize = str =>
		str
			.normalize('NFD')
			.replace(/[^a-zA-Zs]/g, '')
			.toLowerCase();

	const filterCondition = useCallback(() => {
		const normalizedTerm = normalize(term);
		setQuetions(
			questionsList.nodes.filter(condition =>
				normalize(condition.title).includes(normalizedTerm)
			)
		);
	}, [questionsList, term]);

	useEffect(() => {
		filterCondition();
	}, [filterCondition]);

	useEffect(() => {
		setQuetions(questionsList.nodes);
	}, [setQuetions, questionsList]);

	return (
		<>
			<SEO
				title={metaTitle || 'Perguntas frequentes'}
				description={metaDescription}
				cover={cover.imgix_url}
			/>

			<InnerBanner
				title={pageTitle}
				description={pageDescription}
				bg={banner.imgix_url}
			/>

			<Wrapper>
				<Breadcrumb
					crumbs={[
						{ title: 'Perguntas frequentes', slug: 'perguntas-frequentes' },
					]}
				/>
			</Wrapper>

			<Section>
				<S.Wrapper>
					<S.MainContent>
						<S.SearchBox>
							<H5>Qual sua dúvida?</H5>
							<TextField
								onChange={e => setTerm(e.target.value)}
								name="faq"
								placeholder="Digite um termo ou palavra chave"
								prefix={<SearchIcon />}
								value={term}
							/>
						</S.SearchBox>

						{questions.map(({ title, slug }) => (
							<S.Link to={slug} key={slug}>
								<RightArrowCircleIcon size={24} />
								<span>{title}</span>
							</S.Link>
						))}

						<CTA />
					</S.MainContent>

					<Sidebar />
				</S.Wrapper>
			</Section>
		</>
	);
};

export default PerguntasFrequentesPage;
