import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

import { colors, media, spacing, toRem } from '@styles/Theme';

import { Wrapper as LWrapper } from '@components/Layout/styles';

export const MainContent = styled.div`
	@media (${media.desktop}) {
		flex: 1;
		margin-right: ${spacing(4)};
	}
`;

export const Link = styled(GatsbyLink)`
	position: relative;
	display: flex;
	align-items: center;
	height: ${spacing(9)};
	padding: ${spacing(0, 6, 0, 3)};
	overflow: hidden;
	color: ${colors.darkGrey};
	font-weight: bold;
	font-size: ${toRem(18)};
	text-decoration: none;
	background-color: #fff;
	border-radius: ${spacing(1)};
	cursor: pointer;
	transition: all 0.2s linear;
	user-select: none;

	&:not(:last-child) {
		margin-bottom: ${spacing(1)};

		@media (${media.tablet}) {
			margin-bottom: ${spacing(3)};
		}
	}

	&::before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: ${colors.gradient(150)};
		opacity: 0;
		transition: opacity 0.1s linear;
		content: '';
	}

	svg {
		position: absolute;
		top: ${spacing(3)};
		right: ${spacing(2)};
		flex-shrink: 0;
		color: ${colors.lightGrey};
	}

	span {
		position: relative;
	}

	&:hover {
		color: #fff;

		&::before {
			opacity: 1;
		}

		svg {
			color: #fff;
		}
	}
`;

export const Wrapper = styled(LWrapper)`
	@media (${media.desktop}) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
`;

export const SearchBox = styled.div`
	margin-bottom: ${spacing(2)};

	@media (${media.tablet}) {
		margin-bottom: ${spacing(5)};
	}
`;
